import React from 'react';

import Page from '../shared/Page';
import Funding from './Funding';
import Team from './Team';

const AboutUsPage = () => {
  return (
    <Page>
      <Funding />
      <Team />
    </Page>
  );
};

export default AboutUsPage;
