import React from 'react';

import Layout from '../components/layout';
import AboutUsPage from '../AboutUsPage/AboutUsPage';

export default () => (
  <Layout>
    <AboutUsPage />
  </Layout>
);
