import React from 'react';
import styled from 'styled-components';

import palette from '../shared/palette';

const TeamBubble = props => {
  return (
    <Container>
      <Front>
        <Img src={props.img} alt={props.name} />
      </Front>
      <Back>
        <Name>{props.name}</Name>
        <Tag>{props.tag}</Tag>
      </Back>
    </Container>
  );
};

export default TeamBubble;

const Container = styled.div`
  overflow: hidden;
  position: relative;
  margin: 1rem;
  width: 20rem;
  height: 20rem;
  border-radius: 50%;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);

  :hover > div:last-child {
    opacity: 1;
  }
`;

const Side = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 50%;
`;

const Front = styled(Side)``;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Back = styled(Side)`
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 4rem 1rem;
`;

const Name = styled.p`
  font-size: 2rem;
  color: ${palette.light1};
  text-align: center;
  padding-bottom: 0.5rem;
  border-bottom: 0.5px solid ${palette.light1};
  width: 90%;
  margin: 0 auto;
`;

const Tag = styled.p`
  margin-top: 2rem;
  font-size: 1.5rem;
  color: ${palette.light1};
  text-align: center;
`;
