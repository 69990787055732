import React from 'react';
import styled from 'styled-components';

import palette from '../shared/palette';
import { PrimaryHeading, ParagraphText } from '../shared/typography';
import TeamBubble from './TeamBubble';

// pics
import ginsburgImg from '../assets/ginsburg.jpg';
import hagaImg from '../assets/haga.jpg';
import sarahImg from '../assets/sarah.jpg';
import grantImg from '../assets/grant.jpg';
import nathanImg from '../assets/nathan.png';
import laurenImg from '../assets/lauren.jpeg';
import christineImg from '../assets/christine.jpg';
import shawImg from '../assets/ryan.jpg';

const Team = () => {
  let counter = 0;

  const leads = leadData.map((lead, i) => {
    counter++;

    return (
      <TeamBubble
        key={i + counter}
        name={lead.name}
        tag={lead.tag}
        img={lead.img}
      />
    );
  });

  let undergradCounter = 0;
  const undergrads = [];
  let temp = [];
  undergradData.forEach((undergrad, i) => {
    counter++;
    undergradCounter++;

    const bubble = (
      <TeamBubble
        key={i + counter}
        name={undergrad.name}
        tag={undergrad.tag}
        img={undergrad.img}
      />
    );

    if (undergradCounter % 3 === 0) {
      undergrads.push(bubble);
    } else {
      temp.push(bubble);
    }

    if (temp.length === 2) {
      undergrads.push(
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          {temp[0]}
          {temp[1]}
        </div>
      );
      temp = [];
    }
  });

  const faculties = facultyData.map((faculty, i) => {
    counter++;

    return (
      <TeamBubble
        key={i + counter}
        name={faculty.name}
        tag={faculty.tag}
        img={faculty.img}
      />
    );
  });

  return (
    <Section>
      <Container>
        <TeamHeading>Meet the Team</TeamHeading>
        <TeamCategory>Co-Directors</TeamCategory>
        <BubbleContainer>{leads}</BubbleContainer>
        <TeamCategory>Faculty/Staff Team Contributors</TeamCategory>
        <BubbleContainer>{faculties}</BubbleContainer>
        <TeamCategory>
          Undergraduate Team Members who Helped Develop the WearDuke Study
        </TeamCategory>
        <BubbleContainer>{undergrads}</BubbleContainer>
      </Container>
    </Section>
  );
};

export default Team;

const Section = styled.div`
  padding: 7.5rem 2rem;
  background-color: ${palette.dark1};
`;

const Container = styled.div`
  width: 95%;
  max-width: 105rem;
  margin: 0 auto;
`;

const TeamHeading = styled(PrimaryHeading)`
  color: ${palette.light1};
`;

const TeamCategory = styled(ParagraphText)`
  color: ${palette.light1};
  text-align: center;
  margin-bottom: 3rem;
  font-size: 2rem;
`;

const BubbleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 5rem;
`;

const leadData = [
  {
    name: 'Geoffrey Ginsburg',
    tag: 'MD & PhD, School of Medicine',
    img: ginsburgImg,
  },
  {
    name: 'Susanne Haga',
    tag: 'PhD, School of Medicine',
    img: hagaImg,
  },
];

const undergradData = [
  {
    name: 'Sarah Bond',
    tag: "'21,  Global Health & Public Policy",
    img: sarahImg,
  },
  {
    name: 'Grant Kim',
    tag: "'21, Computer Science & Psychology",
    img: grantImg,
  },
  {
    name: 'Nathan Parikh',
    tag: "'21, Biomedical Engineering & Computer Science",
    img: nathanImg,
  },
  {
    name: 'Christine Wang',
    tag: "'21, Biology & Computer Science",
    img: christineImg,
  },
  {
    name: 'Lauren Willis',
    tag: "'21, Biology & Evolutionary Anthropology",
    img: laurenImg,
  },
];

const facultyData = [
  {
    name: 'Ryan Shaw',
    tag: 'PhD, School of Nursing',
    img: shawImg,
  },
];
