import React from 'react';
import styled from 'styled-components';

import { center } from '../shared/mixins';
import palette from '../shared/palette';
import provostImg from '../assets/dukeprovost.png';
import medicineImg from '../assets/dukemedicine.png';
import healthImg from '../assets/dukehealth.png';
import clinicalImg from '../assets/dukeclinical.png';
import bassImg from '../assets/bassconnections.png';
import { ParagraphText } from '../shared/typography';

const Funding = () => {
  return (
    <Container>
      <LogoContainer>
        <ProvostLogo src={provostImg} alt="Duke Provost" />
        <Logo src={medicineImg} alt="Duke Medicine" />
        <Logo src={healthImg} alt="Duke Health" />
        <Logo src={clinicalImg} alt="Duke Clinical" />
        <Logo src={bassImg} alt="Bass Connections" />
      </LogoContainer>
      <ParagraphText style={{ marginBottom: '5rem' }}>
        We'd like to thank those who make this initiative possible. With their
        support, we are able to continue working toward our vision of student
        health. If you are interested in learning more about or supporting our
        work, please feel free to contact Dr. Susanne Haga
        (susanne.haga@duke.edu). If you would like to make a gift in support of
        WearDuke:
      </ParagraphText>
      <CenterWrapper>
        <a
          target="_blank"
          href="https://www.gifts.duke.edu/dukehealth?designation=3919685"
        >
          <DonateButton>Donate Here!</DonateButton>
        </a>
      </CenterWrapper>
    </Container>
  );
};

export default Funding;

const Container = styled.div`
  padding: 10rem 0rem;
  ${center}
`;

const LogoContainer = styled.div`
  display: flex;
  margin: 0 auto;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 5rem;
`;

const Logo = styled.img`
  /* flex-basis: 20rem; */
  width: auto;
  height: 5rem;
  margin: 2rem;
`;

const ProvostLogo = styled(Logo)`
  height: 7.5rem;
  padding: 0.5rem;
`;

const CenterWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const DonateButton = styled.a`
  display: inline-block;
  font-size: 2rem;
  padding: 2rem 4rem;
  text-decoration: none;
  background-color: ${palette.dark2};
  color: ${palette.light1};
  border-radius: 8px;
  margin: 0 auto;
  letter-spacing: 0.5px;
  transition: 0.3s ease;

  :hover {
    transform: scale(1.03);
  }

  :active {
    transform: scale(1);
  }
`;
